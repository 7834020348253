import type { IMenuItemSDKAction } from '@wix/editor-elements-corvid-utils';
import type { IMenuItemProps } from '../../../Menu.types';

export const createSDKAction = (
  item: IMenuItemProps['item'],
  selected: boolean,
  sdkAction?: IMenuItemSDKAction,
) =>
  sdkAction &&
  ((e: React.MouseEvent) => {
    sdkAction?.(e, {
      ...item,
      selected,
    });
  });

export const createEventListeners = (
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const showMegaMenu = () => setIsHovered(true);
  const hideMegaMenu = () => setIsHovered(false);
  const toggleMegaMenu = () => setIsHovered(isHovered => !isHovered);

  return {
    onMouseEnter: showMegaMenu,
    onMouseLeave: hideMegaMenu,
    onFocus: (e: React.FocusEvent) => {
      const isFromChildToParent = e.target.nextSibling?.contains(
        e.relatedTarget,
      );

      if (isFromChildToParent) {
        e.stopPropagation();
        hideMegaMenu();
      } else {
        showMegaMenu();
      }
    },
    onBlur: (e: React.FocusEvent) => {
      if (
        e.relatedTarget &&
        !e.currentTarget.contains(e.relatedTarget) &&
        !e.relatedTarget?.contains(e.currentTarget) // Fixes Mobile Safari blur issue where clicking MegaMenu links closes it, blocking navigation. Details: ECL-8426
      ) {
        hideMegaMenu();
      }
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      const { target, currentTarget, key } = e;

      const menuItemLabel = currentTarget.firstChild as HTMLElement;
      if (menuItemLabel !== target) {
        return;
      }

      switch (key) {
        case 'ArrowLeft':
        case 'ArrowRight':
          // const isRtl = getIsRTL(currentTarget.parentElement as HTMLElement);
          const isRtl = false;

          const arrowToSibling = {
            ArrowLeft: isRtl ? 'nextSibling' : 'previousSibling',
            ArrowRight: isRtl ? 'previousSibling' : 'nextSibling',
          } as const;

          const siblingNavigateTo = currentTarget[arrowToSibling[key]];
          (siblingNavigateTo?.firstChild as HTMLElement)?.focus();
          break;
        case 'Enter':
          toggleMegaMenu();
          break;
        case ' ':
          e.preventDefault();
          toggleMegaMenu();
          break;
        default:
          break;
      }
    },
  };
};
