import type { DropdownAnchorType } from '../../../Menu.types';

export const dataSelectors = {
  dropdown: 'data-dropdown',
};

export const showDropdown = (itemEl: HTMLElement) => {
  const dropdownEl = itemEl.querySelector(
    `[${dataSelectors.dropdown}]`,
  ) as HTMLElement | null;
  if (!dropdownEl) {
    return;
  }

  itemEl.dataset.hovered = 'calculating';

  const computedStyle = window.getComputedStyle(dropdownEl);
  const anchor = computedStyle.getPropertyValue(
    '--dropdown-anchor',
  ) as DropdownAnchorType;
  const align = computedStyle.getPropertyValue('--dropdown-align');

  const width = {
    screen: '100vw',
    menu: '100%',
    menuItem: 'max-content',
  }[anchor as 'screen' | 'menu' | 'menuItem'];

  dropdownEl.style.setProperty('width', width);

  if (anchor === 'screen') {
    const left = (dropdownEl.offsetParent as HTMLElement)?.offsetLeft;
    dropdownEl.style.setProperty('left', `-${left}px`);
  }

  if (anchor === 'menu') {
    dropdownEl.style.setProperty('left', '0');
  }

  if (anchor === 'menuItem') {
    if (align === 'start') {
      dropdownEl.style.setProperty('left', `${itemEl.offsetLeft}px`);
    }

    if (align === 'center') {
      dropdownEl.style.setProperty(
        'left',
        `${
          itemEl.offsetLeft + (itemEl.offsetWidth - dropdownEl.offsetWidth) / 2
        }px`,
      );
    }

    if (align === 'end') {
      dropdownEl.style.setProperty(
        'left',
        `${itemEl.offsetLeft + itemEl.offsetWidth - dropdownEl.offsetWidth}px`,
      );
    }
  }

  itemEl.dataset.hovered = 'true';

  return () => {
    dropdownEl.removeAttribute('style');
    delete itemEl.dataset.hovered;
  };
};
