import type { PropsWithChildren } from 'react';
import cn from 'clsx';
import React from 'react';
import { useMenuItemContext } from '../../../../../common/menu/MenuItemContext';
import { dataSelectors } from '../MenuItem/showDropdown';
import classes from '../MenuItem/style/MenuItem.scss';
import { testIds } from '../../constants';

type DropdownWrapperProps = PropsWithChildren<{
  id: string;
  className?: string;
  dataAttributes?: any;
}>;

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  id,
  className,
  dataAttributes,
  children,
}) => {
  const { item } = useMenuItemContext();

  return (
    <div
      id={id}
      {...dataAttributes}
      className={cn(className, classes.dropdown)}
      role="group"
      aria-label={item.label}
      data-testid={testIds.childrenWrapper}
      {...{ [dataSelectors.dropdown]: true }}
    >
      {children}
    </div>
  );
};
