import React from 'react';
import type { MenuItemProps } from '@wix/editor-elements-definitions';
import Submenu from '../../../../Submenu/viewer/Submenu';
import classes from './VirtualDropdown.scss';
import { DropdownWrapper } from '../DropdownWrapper/DropdownWrapper';

type VirtualDropdownProps = {
  item: MenuItemProps;
};

export const VirtualDropdown = ({ item }: VirtualDropdownProps) => {
  return (
    <DropdownWrapper
      id={`${item.id}-dropdown`}
      className={classes.virtualDropdown}
    >
      <Submenu id={`${item.id}-submenu`} />
    </DropdownWrapper>
  );
};
