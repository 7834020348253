import React, { useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/components/Link';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { IMenuItemProps } from '../../../Menu.types';
import { MenuItemContext } from '../../../../../common/menu/MenuItemContext';
import { createEventListeners, createSDKAction } from './utils';
import { showDropdown } from './showDropdown';
import classes from './style/MenuItem.scss';
import { testIds } from '../../constants';
import { isCurrentItem } from '../../../../../common/menu/getCurrentMenuItem';
import menuSemanticClassNames from '../../../Menu.semanticClassNames';
import shmSemanticClassNames from '../../../../StylableHorizontalMenu/StylableHorizontalMenu.semanticClassNames';
import DropdownIcon from '../../assets/dropdownIcon.svg';
import { VirtualDropdown } from '../VirtualDropdown';

const itemWrapperClassName = classNames(
  classes.itemWrapper,
  formatClassNames(shmSemanticClassNames.menuItemWrapper),
);

const itemLabelClassName = classNames(
  classes.label,
  formatClassNames(shmSemanticClassNames.menuItemLabel),
  formatClassNames(menuSemanticClassNames.itemLabel),
);

export const MenuItem = (props: IMenuItemProps) => {
  const {
    item,
    currentItem,
    onItemClick,
    onItemDblClick,
    onItemMouseIn,
    onItemMouseOut,
    previewState,
  } = props;
  const { label, link, children, forceHovered } = item;
  const [isHovered, setIsHovered] = useState(false);
  const eventListeners = createEventListeners(setIsHovered);
  const itemsCount = item.items?.length ?? 0;

  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!isHovered || !itemRef.current) {
      return;
    }
    return showDropdown(itemRef.current);
  }, [isHovered]);

  useEffect(() => {
    setIsHovered(!!forceHovered);
  }, [forceHovered]);

  const isCurrentPage = isCurrentItem(item, currentItem);

  const itemLabelContainerClassName = classNames(
    classes.labelContainer,
    isCurrentPage ? classes.selected : '',
    formatClassNames(menuSemanticClassNames.item),
  );

  return (
    <li
      className={itemWrapperClassName}
      ref={itemRef}
      {...eventListeners}
      data-testid={testIds.menuItem}
      data-item-depth="0" // For scrolling,  To know how much items on depth=0
    >
      <Link
        {...link}
        className={classes.itemLink}
        activateByKey="Enter"
        onClick={createSDKAction(item, isCurrentPage, onItemClick)}
        onMouseEnter={createSDKAction(item, isCurrentPage, onItemMouseIn)}
        onMouseLeave={createSDKAction(item, isCurrentPage, onItemMouseOut)}
        onDoubleClick={createSDKAction(item, isCurrentPage, onItemDblClick)}
        {...(!!children && {
          'aria-haspopup': true,
          ...(!link?.href && {
            role: 'button',
          }),
        })}
        tabIndex={0}
      >
        <div
          className={itemLabelContainerClassName}
          data-preview={previewState}
          data-testid={testIds.itemLabel}
        >
          <span className={itemLabelClassName}>{label}</span>
          {item.children && <DropdownIcon className={classes.arrow} />}
        </div>
      </Link>

      {(children || itemsCount > 0) && (
        <MenuItemContext.Provider value={{ item }}>
          {children ?? <VirtualDropdown item={item} />}
        </MenuItemContext.Provider>
      )}
    </li>
  );
};
